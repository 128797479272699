import { Component, Input, OnDestroy } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  LookupService,
  RnBooleanRNResponseRelay,
  RnCountryCodesVM,
  RnLUCountryCodesOrderByTypes,
  RnStringStringKeyValuePair,
  RnUserCreateUpdateXid,
  RnUserIsEmailUnique,
  RnUsersProfileVM,
  RnUserUpdatePayload,
  UserService,
} from "src/app/shared/services/rnapi2-service";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { Output, EventEmitter } from "@angular/core";
import { ConstantsService } from "src/app/shared/services/constants/constants.service";
import { ModalService } from "@rn-platform/frontend-shared-ui-common";
import { LoggedInInfoService } from "src/app/shared/services/loggedInInfo/logged-in-info.service";
import { noWhitespaceValidator } from "src/app/shared/validation/white-space-validator.service";
import { HttpResponse } from "@angular/common/http";
import { UserLoginState } from "src/app/core/models/user.login.state";
import { UserCacheService } from "src/app/shared/services/user-cache/user-cache.service";
import { ProfileModalService } from "src/app/shared/services/profileModalService/profile-modal.service";
import { UserDetailUpdateEventData } from "./user-details-update-event-data.model";

@Component({
  selector: "app-user-details-modal",
  templateUrl: "./user-details-modal.component.html",
  styleUrls: ["./user-details-modal.component.scss"],
})
export class UserDetailsModalComponent implements OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() hasAccountOwner: boolean;
  @Output() pendingValidationEvent = new EventEmitter<boolean>();
  @Output() nameChangeEvent = new EventEmitter<string>();
  @Input() reset: boolean = false;
  @Input() userId: number;
  @Input() modalId: string;
  @Output() userUpdated = new EventEmitter<UserDetailUpdateEventData>();

  UserDetailsModalInfo = {
    title: "Edit",
    modal_arialabel: "edit user",
    id_prefix: "edit-user-modal-",
  };
  previousUserDetailsForm: FormGroup;
  private loggedInUser: UserLoginState;
  public user: RnUsersProfileVM;
  public loadingCountries = true;
  public userDetailsForm: FormGroup;
  public nonspecific = false;
  public rnUserIsEmailUnique: RnUserIsEmailUnique;
  public rolesTooltip: string;
  public xidHelp: string;
  public pendingEmailValidation = false;
  public emailUnique = true;
  public countryCodesArray: RnCountryCodesVM[];
  public phoneNumberMask = "(000) 000-0000";
  public supportPINMask = "0000";
  public isPINRequired = true;
  public userInfoInEditMode = false;
  private subscriptions: Subscription[] = [];
  private loggedInUserState: UserLoginState;
  public orgName: string = "Organization";
  public userRole: string = "Role";
  showStaticFirstLastName = false;
  canEditUserDetails = false;
  showStaticPhoneNumber = false;
  phoneNumberEmpty = false;
  canUserViewOrgNavigation = false;
  canEditUserRole = false;
  showStaticUserType = false;
  showStaticExternalID = true;
  canEditXID = false;
  showStaticSupportPin = false;
  canEditSupportPin = false;
  resendActivation = false;
  resendActivationDone = false;
  copyActivationLink = false;
  resetUserPassword = false;
  resetUserPasswordDone = false;
  unlockUser = false;
  unlockUserDone = false;
  changePackageMngAddOnTooltipText: string;
  loadingRoles = true;
  canNavigateToUsers = false;
  disabledBillingSeparationTooltip = "";
  canShowChangePackageButton = false;
  isChangePackageButtonDisabled = false;
  canShowEditAddonsButton = false;
  isChangeManageAddonsDisabled = false;
  canShowViewEvents = false;
  showEditClientAccessButton = false;
  showViewClientAccessButton = false;
  showResendActivationButton = false;
  showCopyActivationLinkButton = false;
  showResetPasswordButton = false;
  showUnlockAccountButton = false;
  hideProfileFlagged = true;
  showProfile = false;
  visible = true;
  isLoading = true;
  isSaving = false;
  private alwaysShowReadOnly: boolean;
  private checkPermissionsValue: boolean;

  constructor(
    private constantsService: ConstantsService,
    private userService: UserService,
    private loggedInInfoService: LoggedInInfoService,
    private lookupService: LookupService,
    protected modalService: ModalService,
    private formBuilder: FormBuilder,
    private userCacheService: UserCacheService,
    private profileModalService: ProfileModalService,
  ) {
    this.loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    this.canEditSupportPin = this.loggedInInfoService.loggedInUserHasRights([
      "EDTSPRTPIN",
    ]);
    this.userDetailsForm = this.formBuilder.group({
      firstName: new FormControl(null, Validators.required.bind(Validators)),
      lastName: new FormControl(null, Validators.required.bind(Validators)),
      email: new FormControl(null, [
        Validators.required.bind(Validators),
        noWhitespaceValidator,
        Validators.pattern(
          /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        ),
      ]),
      countryCode: new FormControl(null),
      phoneNumber: new FormControl(null, [
        Validators.required.bind(Validators),
        Validators.minLength(10),
      ]),
      role: new FormControl(null, Validators.required.bind(Validators)),
      xid: new FormControl(null),
      supportPIN: new FormControl(null, [
        Validators.required.bind(Validators),
        Validators.pattern("\\-?\\d*\\.?\\d{1,2}"),
      ]),
    });
    const loggedInUserLoad = this.loggedInInfoService.LoggedInUser.subscribe(
      (u) => {
        this.loggedInUserState = u;
        this.setSecurityForEditMode();
        this.setFlags();
      },
    );
    this.subscriptions.push(loggedInUserLoad);
    const countryCodes = this.lookupService
      .apiV2LookupCountrycodesGet(
        RnLUCountryCodesOrderByTypes.OrderByName,
        null,
        0,
        0,
        false,
        "response",
      )
      .subscribe((r) => {
        this.loadingCountries = false;
        this.countryCodesArray = r.body.data.Results;
      });
    this.subscriptions.push(countryCodes);
    this.xidHelp = this.constantsService.XIDHelp;
    // make sure we have payment on file info for any purchase flows
    this.showStaticExternalID = !this.canEditXID;
    const sub = this.profileModalService.onUpdate().subscribe((r) => {
      if (r === false) {
        this.formInit();
        this.cancel();
      } else if (typeof r === "boolean") {
        this.close();
      }
    });
    this.subscriptions.push(sub);
  }

  public formInit(): void {
    this.isLoading = true;
    const userLoad = this.userCacheService
      .getUser(this.userId.toString(), true)
      .subscribe({
        next: (response) => {
          this.user = response.body.data;
        },
        error: (err) => {
          console.error(err);
        },
        complete: () => {
          this.xidHelp = this.constantsService.XIDHelp;
          this.orgName = this.user.OrganizationName;
          this.userRole = this.user.UserType;
          this.previousUserDetailsForm = this.userDetailsForm;
          this.canEditXID = this.loggedInInfoService.loggedInUserHasRights([
            "ADDUPDTXID",
          ]);
          this.showStaticExternalID = !this.canEditXID;
          this.checkEmailValid(this.user?.EmailAddress);
          this.startEditingUserInfo();
          this.fillInUserInfoForm();
          this.isLoading = false;
        },
      });

    this.subscriptions.push(userLoad);
    this.lookupService
      .apiV2LookupCountrycodesGet(
        RnLUCountryCodesOrderByTypes.OrderByName,
        null,
        0,
        0,
        false,
        "response",
      )
      .subscribe((r) => {
        this.loadingCountries = false;
        this.countryCodesArray = r.body.data.Results;
      });
    this.countryCodeValidator();
  }

  public hasFormChanged(): boolean {
    let formChange = false;
    if (!this.user) {
      return false;
    }
    // Comparing only the the fields that can be changed
    const firstNameChanged =
      this.previousUserDetailsForm?.value?.firstName?.trim() !==
      this.userDetailsForm?.value?.firstName?.trim();
    const lastNameChanged =
      this.previousUserDetailsForm?.value?.lastName?.trim() !==
      this.userDetailsForm?.value?.lastName?.trim();
    const emailChanged =
      this.previousUserDetailsForm?.value?.email?.trim() !==
      this.userDetailsForm?.value?.email?.trim();
    const countryCodeChanged =
      this.previousUserDetailsForm?.value?.countryCode?.trim() !==
      this.userDetailsForm?.value?.countryCode?.trim();
    const phoneNumberChanged =
      this.previousUserDetailsForm?.value?.phoneNumber !==
      this.userDetailsForm?.value?.phoneNumber;
    const roleChanged =
      this.previousUserDetailsForm?.value?.role !==
      this.userDetailsForm?.value?.role;
    const xidChanged =
      this.previousUserDetailsForm?.value?.xid?.trim() !==
      this.userDetailsForm?.value?.xid?.trim();
    const supportPINChanged =
      this.previousUserDetailsForm?.value?.supportPIN !==
      this.userDetailsForm?.value?.supportPIN;
    // Check if any of the fields have changed
    formChange =
      firstNameChanged ||
      lastNameChanged ||
      emailChanged ||
      countryCodeChanged ||
      xidChanged ||
      phoneNumberChanged ||
      roleChanged ||
      supportPINChanged;

    // Check if any of the fields are empty
    if (
      this.userDetailsForm?.value?.firstName?.trim() === "" ||
      this.userDetailsForm?.value?.lastName?.trim() === "" ||
      this.userDetailsForm?.value?.email?.trim() === "" ||
      this.userDetailsForm?.value?.phoneNumber === "" ||
      this.userDetailsForm?.value?.supportPIN?.trim() === ""
    ) {
      formChange = false;
    }

    // Check if pin is only 4 digits
    if (this.userDetailsForm?.value?.supportPIN?.length !== 4) {
      formChange = false;
    }
    return formChange;
  }

  public setPreviousUserDetailsForm(userDetailsFormCopy: FormGroup): void {
    this.previousUserDetailsForm = new FormGroup({
      firstName: new FormControl(userDetailsFormCopy.get("firstName")?.value),
      lastName: new FormControl(userDetailsFormCopy.get("lastName")?.value),
      email: new FormControl(userDetailsFormCopy.get("email")?.value),
      countryCode: new FormControl(
        userDetailsFormCopy.get("countryCode")?.value,
      ),
      phoneNumber: new FormControl(
        userDetailsFormCopy.get("phoneNumber")?.value,
      ),
      role: new FormControl(userDetailsFormCopy.get("role")?.value),
      xid: new FormControl(userDetailsFormCopy.get("xid")?.value),
      supportPIN: new FormControl(userDetailsFormCopy.get("supportPIN")?.value),
    });
  }

  startEditingUserInfo(): void {
    this.setPreviousUserDetailsForm(this.userDetailsForm);
    this.userInfoInEditMode = true;
    this.setFlags();
  }

  private fillInUserInfoForm(): void {
    this.isLoading = true;
    if (!this.user.ProfileCreated) {
      this.userDetailsForm.controls["countryCode"].clearValidators();
      this.userDetailsForm.controls["countryCode"].updateValueAndValidity();
    }
    this.userDetailsForm.controls["firstName"].setValue(this.user?.FirstName);
    this.userDetailsForm.controls["lastName"].setValue(this.user?.LastName);
    this.userDetailsForm.controls["email"].setValue(this.user?.EmailAddress);
    this.userDetailsForm.controls["countryCode"].setValue(
      this.user && this.user?.CountryCode?.trim()
        ? this.user?.CountryCode?.trim()
        : "",
    );
    this.userDetailsForm.controls["phoneNumber"].setValue(
      this.user?.AreaCode?.trim() + this.user?.PhoneNumber?.trim(),
    );
    this.userDetailsForm.controls["role"].setValue(this.user?.UserTypeID);
    this.userDetailsForm.controls["xid"].setValue(this.user?.ExternalID);
    this.userDetailsForm.controls["supportPIN"].setValue(
      this.user && this.user?.SupportPIN ? this.user?.SupportPIN.trim() : "",
    );
    this.phoneNumberValidator(this.user?.CountryCode?.trim());
    this.userDetailsForm.markAsPristine();
    this.loggedInInfoService.refreshLoggedInUser();
  }

  setPhoneNumberRequired() {
    if (
      !this.userDetailsForm.controls["phoneNumber"].value &&
      !this.user?.ProfileCreated
    ) {
      if (this.userDetailsForm.controls["countryCode"].dirty) {
        const currentErrors =
          this.userDetailsForm.controls["phoneNumber"].errors;

        this.userDetailsForm.controls["phoneNumber"].setValidators([
          Validators.required.bind(Validators),
          Validators.maxLength(10),
        ]);
        this.userDetailsForm.controls["phoneNumber"].updateValueAndValidity();
        // if there are no existing errors, mark this as "untouched"
        if (!currentErrors) {
          this.userDetailsForm.controls["phoneNumber"].markAsUntouched();
        }
      }
    }
    this.userDetailsForm.markAsPristine();
  }
  public checkEmailValid(email: string): boolean {
    const EmailRegex = "[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$";
    const regex = new RegExp(EmailRegex);
    return regex.test(email);
  }

  phoneNumberValidator(val): void {
    const validator = [];
    if (val) {
      if (
        (this.user?.ProfileCreated && this.user?.OrganizationID > 0) ||
        this.user?.PhoneNumber
      ) {
        validator.push(
          Validators.required.bind(Validators),
          Validators.pattern("\\-?\\d*\\.?\\d{1,2}"),
        );
      }
      if (val == 1) {
        validator.push(
          Validators.maxLength(10),
          Validators.pattern("\\-?\\d*\\.?\\d{1,2}"),
        );
      } else {
        validator.push(Validators.pattern("\\-?\\d*\\.?\\d{1,2}"));
        const index = validator.indexOf(Validators.maxLength(10));
        if (index > 0) {
          validator.splice(index, 1);
        }
      }
      const index = validator.indexOf(Validators.minLength(10));
      if (index > 0) {
        validator.splice(index, 1);
      }
    } else {
      validator.push(Validators.maxLength(10));
    }
  }

  setPendingEmailValidation(pendingEmailValidation: boolean) {
    this.pendingEmailValidation = pendingEmailValidation;
  }

  public showLoader = (): boolean => this.isLoading || this.isSaving;

  saveUserInfo() {
    this.isSaving = true;
    const saving = forkJoin([
      this.updateUserInfo(),
      this.updateXID(),
    ]).subscribe({
      next: ([userInfoResponse, _]) => {
        if (userInfoResponse.body.Success) {
          this.user.FirstName = this.userDetailsForm.value["firstName"];
          this.user.LastName = this.userDetailsForm.value["lastName"];
          this.user.EmailAddress = this.userDetailsForm.value["email"];
          this.user.CountryCode = this.userDetailsForm.value["countryCode"];
          const PhoneNumber = this.userDetailsForm.value["phoneNumber"];
          this.user.AreaCode = PhoneNumber.substring(0, 3);
          this.user.PhoneNumber = PhoneNumber.substring(3);
          this.user.SupportPIN = this.userDetailsForm.value["supportPIN"];
          this.profileModalService.setName(
            this.user.FirstName + " " + this.user.LastName,
          );
          this.modalService.close(this.modalId);
          this.setPreviousUserDetailsForm(this.userDetailsForm);
          this.userInfoInEditMode = false;
          this.setFlags();
          const updateEventData = new UserDetailUpdateEventData(
            this.user.UserID,
            this.user.FirstName,
            this.user.LastName,
            this.user.EmailAddress,
            this.user.CountryCode,
            this.user.AreaCode,
            this.user.PhoneNumber,
            this.user.SupportPIN,
            this.user.ExternalID,
          );
          this.userUpdated.emit(updateEventData);
        }
      },
      error: (err) => {
        console.error(err);
        this.isSaving = false;
      },
      complete: () => {
        this.isSaving = false;
        this.close();
      },
    });
    this.subscriptions.push(saving);
  }

  private updateUserInfo(): Observable<HttpResponse<RnBooleanRNResponseRelay>> {
    const payload = new RnUserUpdatePayload();
    payload.User_ID = this.user.UserID;
    payload.AffectedOrganizationId = this.loggedInUserState?.OrganizationID;
    payload.ItemsToUpdate = [];
    let needUpdate = false;
    if (this.userDetailsForm.value["firstName"] !== this.user?.FirstName) {
      const fNameUpdate = new RnStringStringKeyValuePair();
      fNameUpdate.Key = "FirstName";
      fNameUpdate.Value = this.userDetailsForm.value["firstName"];
      payload.ItemsToUpdate.push(fNameUpdate);
      needUpdate = true;
    }
    if (this.userDetailsForm.value["lastName"] !== this.user?.LastName) {
      const lNameUpdate = new RnStringStringKeyValuePair();
      lNameUpdate.Key = "LastName";
      lNameUpdate.Value = this.userDetailsForm.value["lastName"];
      payload.ItemsToUpdate.push(lNameUpdate);
      needUpdate = true;
    }
    if (this.userDetailsForm.value["email"] !== this.user?.EmailAddress) {
      const eMailUpdate = new RnStringStringKeyValuePair();
      eMailUpdate.Key = "Email";
      eMailUpdate.Value = this.userDetailsForm.value["email"];
      payload.ItemsToUpdate.push(eMailUpdate);
      needUpdate = true;
    }
    if (
      this.userDetailsForm.value["countryCode"] !==
      this.user?.CountryCode?.trim()
    ) {
      const cCodeUpdate = new RnStringStringKeyValuePair();
      cCodeUpdate.Key = "CountryCode";
      cCodeUpdate.Value = this.userDetailsForm.value["countryCode"];
      payload.ItemsToUpdate.push(cCodeUpdate);
      needUpdate = true;
    }

    const phoneNumberFull = this.userDetailsForm.value["phoneNumber"];
    const areaCode = phoneNumberFull.substring(0, 3);
    const phoneNumber = phoneNumberFull.substring(3);

    if (areaCode && areaCode !== this.user?.AreaCode) {
      const areaCodeUpdate = new RnStringStringKeyValuePair();
      areaCodeUpdate.Key = "AreaCode";
      areaCodeUpdate.Value = areaCode;
      payload.ItemsToUpdate.push(areaCodeUpdate);
      needUpdate = true;
    }
    if (phoneNumber && phoneNumber !== this.user?.PhoneNumber.trim()) {
      const phoneNoUpdate = new RnStringStringKeyValuePair();
      phoneNoUpdate.Key = "PhoneNumber";
      phoneNoUpdate.Value = phoneNumber;
      payload.ItemsToUpdate.push(phoneNoUpdate);
      needUpdate = true;
    }

    const supportPINEditVal = this.userDetailsForm.value["supportPIN"];
    const supportPINCurrVal = this.user?.SupportPIN;
    if (
      supportPINCurrVal !== supportPINEditVal &&
      !(supportPINEditVal === "" && !supportPINCurrVal)
    ) {
      const supportPINUpdate = new RnStringStringKeyValuePair();
      supportPINUpdate.Key = "SupportPIN";
      supportPINUpdate.Value = this.userDetailsForm.value["supportPIN"];
      payload.ItemsToUpdate.push(supportPINUpdate);
      needUpdate = true;
    }
    if (needUpdate) {
      this.userDetailsForm.markAsPristine();
      return this.userService.apiV2UsersUpdatePost(payload, "response");
    }
    this.userDetailsForm.markAsPristine();
    return this.getSuccessBoolResponse();
  }

  countryCodeValidator() {
    this.userDetailsForm.controls.phoneNumber.valueChanges.subscribe(
      (phoneNumber) => {
        if (phoneNumber) {
          if (!/^\d+$/.test(phoneNumber)) {
            this.userDetailsForm.controls["phoneNumber"].setValidators([
              Validators.pattern("\\-?\\d*\\.?\\d{1,2}"),
              Validators.maxLength(10),
            ]);
          }
          this.userDetailsForm.controls["countryCode"].setValidators([
            Validators.required.bind(Validators),
            Validators.maxLength(10),
          ]);
        } else if (this.user?.PhoneNumber) {
          this.userDetailsForm.controls["countryCode"].setValidators([
            Validators.required.bind(Validators),
          ]);
        } else {
          this.userDetailsForm.controls["countryCode"].clearValidators();
        }
        this.userDetailsForm.controls["countryCode"].updateValueAndValidity();
      },
    );
    this.userDetailsForm.markAsPristine();
  }

  private updateXID(): Observable<HttpResponse<RnBooleanRNResponseRelay>> {
    if (this.userDetailsForm.value["xid"] !== this.user?.ExternalID) {
      const xidUpdatePayload = new RnUserCreateUpdateXid();
      xidUpdatePayload.UserID = this.user?.UserID;
      xidUpdatePayload.AffectedOrganizationId =
        this.loggedInUserState?.OrganizationID;
      xidUpdatePayload.Xid = this.userDetailsForm.value["xid"];
      xidUpdatePayload.IsCreate =
        !this.user?.ExternalID || this.user?.ExternalID.trim() === "";
      return this.userService.apiV2UsersUpdatexidPost(
        xidUpdatePayload,
        "response",
      );
    }
    return this.getSuccessBoolResponse();
  }
  private getSuccessBoolResponse(): Observable<
    HttpResponse<RnBooleanRNResponseRelay>
  > {
    const rnResponse = new RnBooleanRNResponseRelay();
    rnResponse.data = false;
    rnResponse.Success = false;
    const response = new HttpResponse({ body: rnResponse, status: 200 });
    return of(response);
  }

  public checkEmailUnique(emailUnique: boolean) {
    this.emailUnique = emailUnique;
  }
  inEditingMode(): boolean {
    return this.userInfoInEditMode;
  }

  cancel() {
    const userId = this.loggedInUser.UserID.toString();
    if (this.visible) {
      this.fillInUserInfoForm();
      this.modalService.close(this.modalId);
      this.visible = false;
    }
  }

  close() {
    this.visible = true;
    this.cancel();
  }

  canSave(): boolean {
    return (
      this.hasFormChanged() &&
      this.checkEmailValid(this.userDetailsForm?.value?.email?.trim()) &&
      this.emailUnique
    );
  }

  initializeForm(): void {
    this.userDetailsForm.controls.email.setValue(this.user.EmailAddress);
  }

  showStaticEmail(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  pendingValidationChanged(value: boolean) {
    this.pendingValidationEvent.emit(value);
  }

  private setFlags() {
    this.showStaticFirstLastName =
      !this.inEditingMode() ||
      this.alwaysShowReadOnly ||
      !this.checkPermissionsValue;
    this.showStaticPhoneNumber =
      !this.inEditingMode() ||
      this.alwaysShowReadOnly ||
      !this.checkPermissionsValue;
    if (
      this.user &&
      this.user.CountryCode &&
      this.user.CountryCode.trim().length > 0
    ) {
      this.phoneNumberEmpty =
        this.user?.CountryCode === "" &&
        this.user?.AreaCode === "" &&
        this.user?.PhoneNumber === "";
    } else {
      this.phoneNumberEmpty = true;
    }
    this.setshowStaticSupportPin();
    this.setIsPINRequired();
  }

  setshowStaticSupportPin(): void {
    if (
      this.userInfoInEditMode &&
      this.loggedInUserState?.UserID === this.user?.UserID
    ) {
      this.showStaticSupportPin = false;
    } else {
      this.showStaticSupportPin =
        !this.loggedInInfoService.loggedInUserHasRights(["EDTSPRTPIN"]) ||
        !this.user?.ProfileCreated;
    }
  }

  setIsPINRequired(): void {
    this.isPINRequired = this.user?.ProfileCreated;
  }

  private setSecurityForEditMode(): void {
    if (this.loggedInUserState && this.user) {
      this.alwaysShowReadOnly =
        !this.loggedInUserState.IsInternal() &&
        this.user.UserType === "Account Owner" &&
        this.loggedInUserState.UserID !== this.user.UserID;

      this.checkPermissionsValue =
        this.loggedInUserState.UserID === this.user.UserID ||
        this.loggedInInfoService.loggedInUserHasRights(["EDTUSRCONT"]);
      this.setFlags();
    }
  }

  get role() {
    return this.userDetailsForm.get("role");
  }
  get firstName() {
    return this.userDetailsForm.get("firstName");
  }
  get lastName() {
    return this.userDetailsForm.get("lastName");
  }
  get email() {
    return this.userDetailsForm.get("email");
  }
  get confirmEmail() {
    return this.userDetailsForm.get("confirmEmail");
  }

  get phoneNumber() {
    return this.userDetailsForm.get("phoneNumber");
  }
  get supportPin() {
    return this.userDetailsForm.get("supportPin");
  }

  get f() {
    return this.userDetailsForm?.controls;
  }
}
