import { GridColumnBaseConfiguration } from "./grid-column-base-configuration";
import { GridMenuItem } from "./grid-menu-item";

export class GridMenuColumnConfiguration extends GridColumnBaseConfiguration {
  constructor(
    fieldName: string,
    title: string,
    classname: string,
    sortOrder: string,
    menuItems: GridMenuItem[],
    autoCloseMenu: boolean = true,
  ) {
    super("menu", fieldName, title, classname, sortOrder);
    this.AutoCloseMenu = autoCloseMenu;
    this.MenuItems = menuItems;
    this.CustomDataRetrieval = (dataRow: any) => {
      return this.GetLinkText(dataRow);
    };
  }
  public LinkText: string;
  public GetCustomLinkText: (dataRow: any) => string;
  public GetAction: (menuItemId: string, dataRow: any) => void;
  public IsMenuItemEnabled: (menuItem: GridMenuItem, dataRow: any) => boolean;
  public Target: string;
  public iconClass: string;

  /**
   * AutoCloseMenu will cause the menu to auto close the menu after the action executes,
   * however the action is passed the trigger in case manual close desired
   */
  public AutoCloseMenu: boolean;

  /**
   * List of menu items to display
   */
  public MenuItems: GridMenuItem[] = [];

  public GetLinkText(dataRow: any) {
    if (this.LinkText && this.LinkText !== "") {
      return this.LinkText;
    } else if (this.GetCustomLinkText) {
      return this.GetCustomLinkText(dataRow);
    }
    return dataRow[this.FieldName];
  }

  public TypeName(dataRow: any): string {
    return super.TypeName(dataRow);
  }
}
