<div *ngIf="configuration.ShowHeader">
  <h2 class="{{ configuration.HeaderClass }}">
    {{ configuration.GridHeader }}
  </h2>
</div>
<div
  *ngIf="configuration.ShowCountHeader"
  class="{{ configuration?.HeaderClass | rnGrid: 'headercountclass' }}"
>
  <span
    >{{ configuration.CountHeaderItemsName }}:
    {{ configuration.TotalRecords }} Total</span
  >
</div>
<p-table
  [columns]="configuration.ColumnConfiguration"
  [value]="configuration.GridData"
  [lazy]="configuration.IsLazyLoading"
  (onLazyLoad)="configuration.lazyLoadData($event)"
  loadingIcon="fa-solid fa-spinner"
  [paginator]="false"
  [rows]="pageSize"
  [rowsPerPageOptions]="configuration.RowsPerPageOptions"
  [pageLinks]="configuration.NumberPageLinks"
  [first]="configuration.FirstRow"
  [totalRecords]="configuration.TotalRecords"
  [responsive]="true"
  [(selection)]="selection"
  sortField="{{ configuration.SortField }}"
  [sortOrder]="configuration.SortOrder"
  class="p-datatable {{ configuration.GridClass }} rn-table"
  tableStyleClass="{{ configuration.TableClass }}"
  rowIndexVar="rowIx"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="table-content-font">
      <th
        *ngFor="let col of columns"
        [ngClass]="{ 'c-default': !col.SortOrder }"
        [class]="col.Class"
        [pSortableColumn]="col.SortOrder"
        [pSortableColumnDisabled]="col.SortableColumnDisabled"
      >
        <div *ngIf="col.TypeName() === 'checkbox'">
          <p-tableHeaderCheckbox
            class="checkboxStyle"
            (click)="setSelection($event)"
          ></p-tableHeaderCheckbox>
        </div>
        <div *ngIf="(col | rnGrid: 'coltype') === 'checkboxLimit'">
          <input
            class="checkboxStyle"
            type="checkbox"
            data-testid="tableCheckboxHeader"
            class="checkboxStyle no-href-link"
            *ngIf="col.SelectAllAvailable"
            [checked]="configuration.RnSelectAll"
            (change)="rnSelectAllChanged($event, col)"
            [disabled]="
              configuration.selectAllDisabled ||
              (col | rnGrid: 'rnselectalldisabled')
            "
            tooltipPosition="top"
            pTooltip="{{ col | rnGrid: 'rnSelectAllTooltip' }}"
          />
        </div>
        <div *ngIf="col | rnGrid: 'coltypenotchecktype'">
          {{ col.Title }}
          <p-sortIcon
            *ngIf="col | rnGrid: 'colcanbesorted'"
            [field]="col.SortOrder"
          ></p-sortIcon>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
  >
    <tr
      [ngClass]="configuration.RetrieveClassForRow(rowData)"
      class="table-content-font"
    >
      <td
        data-testid="cellData"
        (click)="rowClicked.emit(rowData)"
        *ngFor="let col of columns"
        class="{{ configuration.RetrieveClassForCell(col, rowData) }} + {{
          col | rnGrid: 'getremovebuttonclassforcell'
        }}"
        [attr.colspan]="configuration.RetrieveColSpanForCell(col, rowData)"
      >
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'linkbutton'">
          <a
            tabindex="0"
            (keyup.enter)="CallLinkButtonAction($event, col, rowData)"
            (click)="CallLinkButtonAction($event, col, rowData)"
            class="{{ col | rnGrid: 'getremovebuttonclassforcell' }}"
            tooltipPosition="top"
            pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
          >
            <i class="{{ col | rnGrid: 'getlinkbuttonclassforcell' }}"></i>
            {{ col | rnGrid: "getlinkbuttontextdataforcolumn" : rowData }}</a
          >
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'menu'">
          <mat-menu id="actionMenu" #appMenu="matMenu" class="custom-menu">
            @for (item of col.MenuItems; track item.menuItemId) {
              <div>
                <button
                  id="{{ item.menuItemId }}"
                  mat-menu-item
                  (click)="CallMenuButtonAction($event, col, item, rowData)"
                  [disabled]="!IsMenuItemEnabled(col, item, rowData)"
                  [ngClass]="{'last-menu-item': item === col.MenuItems[col.MenuItems.length - 1]}"
                >
                  @if (item.iconClass) {
                    <app-icon
                      imgClass="{{ item.iconClass }}"
                      source="{{ item.iconSource }}"
                    ></app-icon>
                  }
                  <span class="action-text">{{ item.text }}</span>
                </button>
              </div>
            }
          </mat-menu>

          <button
            mat-icon-button
            [matMenuTriggerFor]="appMenu"
            class="button-action gridMatIcon menu-button"
          >
            <mat-icon class="margin-bot-10">more_horiz</mat-icon>
          </button>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'hyperlink'">
          <a
            data-testid="user"
            class="no-text-decoration"
            *ngIf="
              (col | rnGrid: 'gethyperlinkenabled' : rowData) &&
              (col | rnGrid: 'gethyperlinkashref' : rowData)
            "
            href="{{ col | rnGrid: 'gethyperlinkhrefdataforcolumn' : rowData }}"
            >{{ col | rnGrid: "gethyperlinktextdataforcolumn" : rowData }}</a
          >
          <a
            id="initiyName"
            [attr.data-testid]="
              col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData
            "
            class="no-text-decoration"
            *ngIf="
              (col | rnGrid: 'gethyperlinkenabled' : rowData) &&
              !(col | rnGrid: 'gethyperlinkashref' : rowData)
            "
            href="javascript:;"
            class="no-href-link"
            (click)="HyperLinkClickHandler(col, rowData)"
            >{{ col | rnGrid: "gethyperlinktextdataforcolumn" : rowData }}</a
          >
          <span
            [attr.data-testid]="
              col | rnGrid: 'gethyperlinktextdataforcolumn' : rowData
            "
            class="no-text-decoration"
            *ngIf="!(col | rnGrid: 'gethyperlinkenabled' : rowData)"
            >{{ col | rnGrid: "gethyperlinktextdataforcolumn" : rowData }}</span
          >
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'affected'">
          <span
            [innerHtml]="col | rnGrid: 'getaffecteddataforcolumn' : rowData"
          ></span>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'html'">
          <span
            [innerHtml]="GetHtmlDataForColumn(col, rowData) | safeHtml"
          ></span>
          <app-button
            id="btnGrid"
            buttonStyle="teritary"
            *ngIf="
              col.falseyButtonText &&
              !rowData.SupportPIN &&
              rowData.UserStateName !== 'Processing' &&
              rowData.UserStateName !== 'Failed' &&
              rowData.UserStateName !== 'Failed - Fatal'
            "
            [label]="col.falseyButtonText"
            (clickActionEmitter)="falseyValueClickHandler(rowData)"
          >
          </app-button>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'boolean'">
          <span
            [innerHtml]="col | rnGrid: 'getbooleandataforcolumn' : rowData"
          ></span>
          <button
            *ngIf="col | rnGrid: 'isbooleanvaluefalsey' : rowData"
            class="rn-btn rn-btn-tertiary-action m-l-8"
            (click)="falseyValueClickHandler(rowData)"
          >
            {{ col.falseyButtonText }}
          </button>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'multivalue'">
          @if (col.useRouterNavigation) {
            <a class="rn-text-link" (click)="userRowClickHandler(rowData)">
              @for (field of col.fieldNames; track $index) {
                <span class="{{ col.classNames[$index] }}">{{
                  rowData[field]
                }}</span>
              }
            </a>
          } @else {
            <span
              [innerHtml]="col | rnGrid: 'getmultivaluedataforcolumn' : rowData"
            ></span>
          }
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'dialog'">
          <a class="no-text-decoration" class="no-href-link">{{
            col | rnGrid: "getdialogvaluedataforcolumn" : rowData
          }}</a>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'generic'">
          <span
            id="cell"
            [attr.data-testid]="configuration.getDataForCell(col, rowData)"
            [innerHtml]="configuration.getDataForCell(col, rowData)"
          ></span>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'linkStyle'">
          <span
            data-testid="linkStyle"
            class="linkStyle"
            [innerHtml]="configuration.getDataForCell(col, rowData)"
          ></span>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'date'">
          <p>{{ col | rnGrid: "getdatevaluedataforcolumn" : rowData }}</p>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'checkbox'">
          <p-tableCheckbox
            class="checkboxStyle"
            (click)="setSelection(rowData)"
            pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
            [disabled]="col | rnGrid: 'isitemdisabled' : rowData"
            [value]="rowData"
          ></p-tableCheckbox>
        </span>
        <span
          *ngIf="(col | rnGrid: 'coltype' : rowData) === 'checkboxLimit'"
          tooltipPosition="top"
          pTooltip="{{ col | rnGrid: 'gettooltipforcell' : rowData }}"
          [escape]="false"
          tooltipZIndex="9999999"
        >
          <input
            class="checkboxStyle"
            type="checkbox"
            data-testid="tableCheckbox"
            class="checkboxStyle no-href-link"
            [ngModel]="rowData.RnTableSelected"
            (click)="rncheckboxclick($event, col, rowData)"
            (change)="RnCheckboxChanged($event, col, rowData)"
            [disabled]="IsItemDisabled(col, rowData)"
          />
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'actions'">
          <div *ngIf="GetPredicateValue(col, rowData)">
            <div
              *ngFor="
                let button of col
                  | rnGrid: 'getactionsforcolumn' : rowData.UserStateName
              "
            >
              <button
                class="{{ button.class }}"
                (click)="actionClickHandler(rowData, button.type)"
              >
                {{ button.text }}
              </button>
            </div>
          </div>
          <div *ngIf="!GetPredicateValue(col, rowData)">
            {{ rowData[col.FieldName] }}
          </div>
        </span>
        <span *ngIf="(col | rnGrid: 'coltype' : rowData) === 'quantity'">
          <app-quantity-control
            [MinValue]="1"
            [DisallowInput]="false"
            [btnStyleClass]="'shopping-cart-quantity-control-btn'"
            [inputStyleClass]="'shopping-cart-quantity-control-input'"
            [DefaultValue]="getDefaultValue(rowData, col)"
            (quantityChangeActionEmitter)="
              quantityChangeHandler($event, rowData, col)
            "
            (quantityEnteredActionEmitter)="
              quantityEnteredHandler($event, rowData, col)
            "
          ></app-quantity-control>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
<div
  *ngIf="configuration.UsePaginator"
  class="paginator-container"
  #navButtons
  data-testid="gridNavButtons"
>
  Rows per page
  <select
    [(ngModel)]="pageSize"
    (change)="pageSizeChanged()"
    class="selectOptionForPagination"
  >
    <option
      *ngFor="let option of configuration.RowsPerPageOptions"
      [value]="option"
    >
      {{ option }}
    </option>
  </select>
  <span
    *ngIf="configuration.TotalRecords > 0"
    data-testid="gridPageButtonContainer"
    class="float-right gridPageButtonContainer"
  >
    {{ configuration?.CurrentFirstRowNumber }}-{{
      configuration?.CurrentFirstRowNumber + configuration.GridData?.length - 1
    }}
    of {{ configuration.TotalRecords }}&nbsp;&nbsp;&nbsp;&nbsp;
    <button
      class="paginator-nav-button"
      data-testid="gridNavButtonLeft"
      [disabled]="currentPageNum === 0"
      (click)="navToPage(currentPageNum - 1)"
    >
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <span *ngFor="let data of paginatorData">
      <button
        class="paginator-nav-button"
        [disabled]="data.pageNumber < 0 || data.pageNumber === currentPageNum"
        [ngClass]="{ 'active-page-item': data.pageNumber === currentPageNum }"
        (click)="navToPage(data.pageNumber)"
        [attr.data-testid]="'gridPageButton_' + data.pageLabel"
      >
        {{ data.pageLabel }}
      </button>
    </span>
    <button
      class="paginator-nav-button"
      data-testid="gridNavButtonRight"
      [disabled]="currentPageNum === getLastPageNumber()"
      (click)="navToPage(currentPageNum + 1)"
    >
      <i class="fa-solid fa-chevron-right"></i>
    </button>
  </span>
</div>
