export class UserDetailUpdateEventData {
  constructor(
    public userId: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public countryCode: string,
    public areaCode: string,
    public phoneNumber: string,
    public supportPIN: string,
    public xid: string,
  ) {}
}
